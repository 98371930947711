/** @jsxImportSource @emotion/react */

import Anchor from 'src/kit/Anchor/Anchor';
import securityIcon from 'src/assets/svg/security-language-savings.svg';
import {
  maticPoliciesDisclosureCSS,
  securityContainerCSS,
  securityHeadingCSS,
  securityLanguageDescriptionCSS,
  securityLanguageLinkCSS
} from './CustomTCPAConsent.style';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { useParams } from 'react-router-dom';
import { useConfig } from 'src/api/config';
import { CustomTCPAConsentProps } from './CustomTCPAConsent.types';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import { Disclosure, DisclosureKind } from 'src/interfaces/disclosures.types';
import templater from 'src/utils/stringTemplater';
import patternReplacer from 'src/utils/patternReplacer';

//TO DO: Refactor so this component is dump and only accepts props and renders it
const CustomTCPAConsent: React.FC<CustomTCPAConsentProps> = ({
  customCSS,
  location,
  shouldRenderMaticPoliciesDisclosure = true,
  shouldHideIcon = false,
  buttonLabel
}) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data } = useDisclosures(gid);
  const agentPhone = config?.partner.agent_phone;
  const isSavingPage = flow === Flows.Savings;
  const specialText = buttonLabel ? buttonLabel : isSavingPage ? 'Continue' : 'Check My Rates';

  const tcpaDisclosure = data?.disclosures?.find((item: Disclosure) => {
    return item.kind === DisclosureKind.CX_TCPA;
  });

  const maticPoliciesDisclosure = data?.disclosures?.find(
    (item: Disclosure) => item.kind === DisclosureKind.Matic_Policies
  );

  const CTAReplaced = templater(tcpaDisclosure?.content || '', { '{cta_text}': specialText });
  const maticPoliciesReplaced = templater(maticPoliciesDisclosure?.content || '', { '{cta_text}': specialText });

  const onPhoneClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, { location, page: SEGMENT.PAGES_KEY.LANDING });

  const agentPhoneAnchor = () => (
    <Anchor
      key="tcpa-phone-link"
      href={`tel:${agentPhone}`}
      onClick={onPhoneClick}
      customCSS={securityLanguageLinkCSS}
      aria-label="Manual Questions"
    >
      {agentPhone}
    </Anchor>
  );

  return (
    <div css={[securityContainerCSS(shouldHideIcon), customCSS]}>
      {!shouldHideIcon && (
        <div css={securityHeadingCSS}>
          <img src={securityIcon} alt="Security Icon" />
          <h2>Your Privacy Matters</h2>
        </div>
      )}

      <div css={[securityLanguageDescriptionCSS(shouldHideIcon)]}>
        {patternReplacer(CTAReplaced, { agent_phone: agentPhoneAnchor() })}
      </div>
      {shouldRenderMaticPoliciesDisclosure && maticPoliciesDisclosure && (
        <div css={[securityLanguageDescriptionCSS(shouldHideIcon)]}>
          <div
            css={maticPoliciesDisclosureCSS}
            dangerouslySetInnerHTML={{
              __html: patternReplacer(maticPoliciesReplaced, { agent_phone: agentPhoneAnchor() }) as string
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomTCPAConsent;
