/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { sectionWrapperCSS, wrapperCSS } from './Savings.style';
import Container from 'src/kit/Container/Container';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import SavingsHero from 'src/components/SavingsHero/SavingsHero';
import { SavingSelectedQuote } from 'src/interfaces/ISavings';
import savingsService from 'src/api/savingsQuotes/savings.service';
import useResponsive from 'src/hooks/useResponsive';
import useConfig from 'src/api/config/useConfig';
import useSavingsQuotes from 'src/api/savingsQuotes/useSavingsQuotes';
import { getAge } from 'src/utils/date';
import { Answers } from 'src/interfaces/IAnswer';
import { HomeQuote, SavingsQuote } from 'src/interfaces/IQuotes';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import { isObjectEmpty } from 'src/utils/general';
import useQuestions from 'src/api/questions/useQuestions';
import useSourceDimension from 'src/api/sourceDimension/useSourceDimension';
import { SavingsProps } from './Savings.types';
import HistoricalPremium from 'src/components/HistoricalPremium/HistoricalPremium';
import { useSession } from 'src/api/session';
import { getReadableAddress } from 'src/utils/homeDetails';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';
import BundleMsgHero from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import { EXPERIMENT_VARIANTS_ENUM as experimentVariants } from 'src/interfaces/experiment.types';
import SavingsMainContent from 'src/components/SavingsMainContent/SavingsMainContent';
import BundleOnlyHero from 'src/components/BundleOnlyHero/BundleOnlyHero';

const Savings: React.FC<SavingsProps> = ({}) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: offer } = useSavingsQuotes(gid);
  const { data: sessionData } = useSession(flow, gid);
  const navigate = useCustomNavigate();
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: disclosuresData, isLoading: isDisclosuresLoading } = useDisclosures(gid);
  useSourceDimension(gid);
  const { isMobile } = useResponsive();
  const { data: questions, isLoading: isQuestionsLoading } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [bundleMessageVariation, setBundleMessageVariation] = useState<string | null>(null);
  const [isBundleOnlyVariation, setIsBundleOnlyVariation] = useState(false);
  const shouldRenderBundleVariation =
    bundleMessageVariation === experimentVariants.TEST_A || bundleMessageVariation === experimentVariants.TEST_B;

  const isHPVariation =
    !!sessionData?.historical_premium?.premium_change_amount && !!sessionData?.historical_premium?.chart_data?.length;

  const flowStartedReported = useRef(false);
  const CTAClickedReported = useRef(false);
  const alreadyTrackedExperiments = useRef<FT[]>([]);

  const features = useFeatureToggle();
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);

  useEffect(() => {
    if (!isObjectEmpty(answers) && answers.person_gid) {
      analytics.identify(answers.person_gid, {
        email: analytics.generateTrackEmail(answers.person_gid as string),
        age: answers.person_date_of_birth ? getAge(questions?.answers.person_date_of_birth as string) : undefined,
        state: offer?.address.state
      });
    }
  }, [answers, questions, offer]);

  useEffect(() => {
    if (flow && gid && config?.servicer_key && !flowStartedReported.current) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow);
      flowStartedReported.current = true;
    }
  }, [flow, gid, config]);

  useEffect(() => {
    if (gid && offer && config) {
      analytics.page(SEGMENT.PAGES.SAVINGS, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });

      analytics.page(SEGMENT.PAGES.QUOTES, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });

      analytics.track(SEGMENT.QUOTE_RECAP, gid, flow, {
        quotes: [
          {
            premium: offer.quotes[0].premium.value,
            carrier_key: offer.quotes[0].carrier.key,
            savings_amount: offer.quotes[0].saving_value
          }
        ]
      });
    }
  }, [flow, gid, offer, config]);

  useEffect(() => {
    const bundleToggleExp = features?.[FT.SF_BUNDLE_MESSAGE];
    const freedomSFToSingleQuote = features?.[FT.FREEDOM_SF_TO_SINGLE_QUOTE];
    const bundleOnlyExp = features?.[FT.SF_BUNDLE_ONLY];

    const experimentsToTrack = [
      {
        experiment: bundleToggleExp,
        callback: () => setBundleMessageVariation(bundleToggleExp!.variation)
      },
      {
        experiment: freedomSFToSingleQuote
      },
      {
        experiment: bundleOnlyExp,
        callback: () => setIsBundleOnlyVariation(!bundleOnlyExp!.isControlVariation)
      }
    ];

    experimentsToTrack.forEach(({ experiment, callback }) => {
      !alreadyTrackedExperiments.current.includes(experiment?.name as FT) &&
        experiment?.track(() => {
          callback?.();
          alreadyTrackedExperiments.current.push(experiment.name);
        });
    });
  }, [features, offer?.quotes]);

  useEffect(() => {
    if (!offer) {
      return;
    }

    if (offer.quotes[0]?.online_bind_url) {
      analytics.track(SEGMENT.ONLINE_BIND_OFFERED, gid, Flows.Savings, {
        ordinal: 1,
        carrier_key: offer.quotes[0]?.carrier.key.toLowerCase()
      });
    }

    if (offer.quotes.filter(q => !q.bundle_only).length === 0) {
      analytics.track(SEGMENT.BUNDLE_ONLY.NO_HOME_QUOTES_PRESENTED, gid, Flows.Savings);
    }
  }, [gid, offer]);

  const onBuyOnline = async (onlineBindUrl: string, data: any) => {
    try {
      await acceptOffer(data);
      window.location.replace(onlineBindUrl);
    } catch {}
  };

  const onCallMe = async (data: any) => {
    setIsSubmitLoading(true);

    await acceptOffer(data);

    navigate(`/${flow}/${gid}/confirmation`);
    setIsSubmitLoading(false);
  };

  const onDigitalProfile = async (url: string, data: any) => {
    await acceptOffer(data);
    analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
    window.open(url, '_self');
    return;
  };

  const onFormSubmit = (quote: SavingsQuote | HomeQuote, phone: string) => {
    const updatedData: SavingSelectedQuote = {
      gid,
      quote_gid: quote.gid
    };
    const onlineBindUrl = quote?.online_bind_url;
    const digitalProfileUrl = quote?.digital_profile_url;

    if (phone !== offer?.person.phone) {
      analytics.track(SEGMENT.PHONE_CHANGE_ATTEMPTED, gid, flow, {
        resolution: SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.SUCCESS
      });
      updatedData.phone = phone;
    }

    if (!CTAClickedReported.current) {
      analytics.track(SEGMENT.PRIMARY_CTA_CLICKED, gid, flow);
    }

    if (isBundleOnlyVariation && quote.gid === offer?.quotes[0].gid) {
      analytics.track(SEGMENT.BUNDLE_ONLY.QUOTE_CHOSEN, gid, flow);
    }

    digitalProfileUrl
      ? onDigitalProfile(digitalProfileUrl, updatedData)
      : onlineBindUrl
        ? onBuyOnline(onlineBindUrl, updatedData)
        : onCallMe(updatedData);
  };

  const onFormSubmitWrapper = (quote: SavingsQuote | HomeQuote, phone: string) => {
    onFormSubmit(quote, phone);
  };

  const acceptOffer = (data: SavingSelectedQuote) => {
    return savingsService.selectQuote(gid, data);
  };

  if (isConfigLoading || !offer || !config?.partner || isDisclosuresLoading || isQuestionsLoading) {
    return <FullPageLoader />;
  }

  const getHeroVariation = () => {
    switch (true) {
      case shouldRenderBundleVariation:
        return (
          <BundleMsgHero
            offer={offer}
            onSubmit={onFormSubmitWrapper}
            isSubmitLoading={isSubmitLoading}
            userHasBundlePreference={questions?.answers?.person_has_core_bundle_interest === 'yes'}
            isSecondTestVariation={bundleMessageVariation === experimentVariants.TEST_B}
            isImplicitDisclosuresAccepted={!!disclosuresData?.disclosures_accepted}
          />
        );
      case isBundleOnlyVariation:
        return (
          <BundleOnlyHero
            offer={offer}
            onSubmit={onFormSubmitWrapper}
            isLoading={isSubmitLoading}
            isDisclosuresAccepted={!!disclosuresData?.disclosures_accepted}
          />
        );
      default:
        return (
          <SavingsHero
            offer={offer}
            onSubmit={onFormSubmitWrapper}
            isSubmitLoading={isSubmitLoading}
            shouldHideBenefitsForMobile={isHPVariation}
          />
        );
    }
  };

  return (
    <div css={wrapperCSS}>
      {getHeroVariation()}

      {isHPVariation && (
        <>
          <HistoricalPremium
            {...sessionData?.historical_premium}
            currentPolicyAmount={sessionData?.current_policy?.premium}
            currentCarrier={sessionData?.current_policy?.carrier_name}
            address={getReadableAddress(answers.property_address as AddressAnswer)}
            personName={`${answers.person_first_name} ${answers.person_last_name}`}
            yearBuild={answers.property_year_built}
            squareFeet={answers.property_square_feet}
            stories={answers.stories}
            state={(answers.property_address as AddressAnswer)?.state}
          />
          {!shouldRenderBundleVariation && isMobile && (
            <Container customCSS={sectionWrapperCSS(false)}>
              <QuoteBenefits
                title="Benefits of switching with Matic"
                benefits={getSavingsBenefitsList(servicerName, config?.servicer_key || '')}
              />
            </Container>
          )}
        </>
      )}

      <SavingsMainContent />
    </div>
  );
};

export default Savings;
